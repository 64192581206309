var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { MarkdownLanguage, } from "@dc-extension-rich-text/language-markdown";
import { createDynamicContentTools, DcContentLinkNode, DcImageLinkNode, } from "@dc-extension-rich-text/prosemirror-dynamic-content";
import DcContentLinkBlock from "./blocks/DcContentLinkBlock";
import DcImageLinkBlock from "./blocks/DcImageLinkBlock";
import MarkdownBlock from "./blocks/MarkdownBlock";
// tslint:disable-next-line
var Schema = require("prosemirror-model").Schema;
// tslint:disable-next-line
var Node = require("prosemirror-model").Node;
var JSONLanguage = /** @class */ (function (_super) {
    __extends(JSONLanguage, _super);
    function JSONLanguage(options, blockTypes) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.name = "json";
        _this.label = "JSON";
        _this.markdownBlock = new MarkdownBlock(options, _this);
        _this.blockTypes = blockTypes || [
            new DcImageLinkBlock(),
            new DcContentLinkBlock(),
            _this.markdownBlock,
        ];
        _this.tools = __spreadArrays(_this.tools, createDynamicContentTools(_this.schema, options));
        return _this;
    }
    JSONLanguage.prototype.serialize = function (doc) {
        var _this = this;
        if (!doc) {
            return [];
        }
        doc = doc.toJSON();
        var fragments = [];
        if (!doc.content || doc.content.length === 0) {
            return fragments;
        }
        var nodesByConverter = groupBy(doc.content, function (node) {
            for (var _i = 0, _a = _this.blockTypes; _i < _a.length; _i++) {
                var blockType = _a[_i];
                if (blockType.canSerialize(_this.schema, node)) {
                    return blockType;
                }
            }
        });
        var blocks = [];
        for (var _i = 0, nodesByConverter_1 = nodesByConverter; _i < nodesByConverter_1.length; _i++) {
            var group = nodesByConverter_1[_i];
            var groupBlocks = group.group.serialize(this.schema, group.items);
            blocks = blocks.concat.apply(blocks, groupBlocks);
        }
        return blocks;
    };
    JSONLanguage.prototype.parse = function (blocks) {
        var _this = this;
        if (!blocks || blocks.length === 0) {
            return Node.fromJSON(this.schema, {
                type: "doc",
                content: [
                    {
                        type: "paragraph",
                    },
                ],
            });
        }
        var result = {
            type: "doc",
            content: [],
        };
        var blocksGroupedByConverter = groupBy(blocks, function (block) {
            for (var _i = 0, _a = _this.blockTypes; _i < _a.length; _i++) {
                var blockType = _a[_i];
                if (blockType.canParse(_this.schema, block)) {
                    return blockType;
                }
            }
        });
        for (var _i = 0, blocksGroupedByConverter_1 = blocksGroupedByConverter; _i < blocksGroupedByConverter_1.length; _i++) {
            var group = blocksGroupedByConverter_1[_i];
            var parsedNodes = group.group.parse(this.schema, group.items);
            result.content = result.content.concat.apply(result.content, parsedNodes);
        }
        return Node.fromJSON(this.schema, result);
    };
    JSONLanguage.prototype.createSchema = function (options) {
        var schema = _super.prototype.createSchema.call(this, options);
        return new Schema({
            nodes: schema.spec.nodes
                .addBefore("image", "dc-image-link", DcImageLinkNode())
                .addBefore("image", "dc-content-link", DcContentLinkNode()),
            marks: schema.spec.marks,
        });
    };
    JSONLanguage.prototype.getNodeSerializers = function () {
        return {
            "dc-image-link": function () { return ""; },
            "dc-content-link": function () { return ""; },
        };
    };
    return JSONLanguage;
}(MarkdownLanguage));
export default JSONLanguage;
function groupBy(items, groupFn) {
    var result = [];
    var currentGroup = null;
    var currentGroupItems = [];
    for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
        var item = items_1[_i];
        var itemGroup = groupFn(item);
        if (itemGroup === undefined) {
            continue;
        }
        if (currentGroup === null) {
            currentGroup = itemGroup;
            currentGroupItems = [item];
        }
        else if (currentGroup === itemGroup) {
            currentGroupItems.push(item);
        }
        else {
            if (currentGroupItems.length > 0) {
                result.push({
                    group: currentGroup,
                    items: currentGroupItems,
                });
            }
            currentGroup = itemGroup;
            currentGroupItems = [item];
        }
    }
    if (currentGroupItems.length > 0 && currentGroup !== null) {
        result.push({
            group: currentGroup,
            items: currentGroupItems,
        });
    }
    return result;
}
