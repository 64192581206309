var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import { canInsert, clearAllMarks, getSelectionMarks, isMarkActive, } from "../utils";
// tslint:disable:no-submodule-imports
import { Badge } from "@material-ui/core";
import Assistant from "@material-ui/icons/Assistant";
import CalendarViewDay from "@material-ui/icons/CalendarViewDay";
import Code from "@material-ui/icons/Code";
import FormatBold from "@material-ui/icons/FormatBold";
import FormatClearIcon from "@material-ui/icons/FormatClear";
import FormatIndentDecrease from "@material-ui/icons/FormatIndentDecrease";
import FormatItalic from "@material-ui/icons/FormatItalic";
import FormatListBulleted from "@material-ui/icons/FormatListBulleted";
import FormatListNumbered from "@material-ui/icons/FormatListNumbered";
import FormatQuote from "@material-ui/icons/FormatQuote";
import ImageIcon from "@material-ui/icons/Image";
import Link from "@material-ui/icons/Link";
import Redo from "@material-ui/icons/Redo";
import Undo from "@material-ui/icons/Undo";
import { isToolEnabled } from "./StandardToolOptions";
// tslint:disable-next-line
var _a = require("prosemirror-history"), undoFn = _a.undo, redoFn = _a.redo;
// tslint:disable-next-line
var _b = require("prosemirror-commands"), toggleMark = _b.toggleMark, setBlockType = _b.setBlockType, lift = _b.lift;
// tslint:disable-next-line
var wrapInList = require("prosemirror-schema-list").wrapInList;
// tslint:disable-next-line
var findParentNode = require("prosemirror-utils").findParentNode;
export function createMarkToggleTool(name, label, icon, type) {
    return {
        name: name,
        label: label,
        displayIcon: icon,
        isActive: function (state) { return isMarkActive(state, type); },
        apply: toggleMark(type),
    };
}
export function undo() {
    return {
        name: "undo",
        label: "Undo",
        displayIcon: React.createElement(Undo, null),
        isEnabled: function (state) { return undoFn(state); },
        apply: undoFn,
    };
}
export function redo() {
    return {
        name: "redo",
        label: "Redo",
        displayIcon: React.createElement(Redo, null),
        isEnabled: function (state) { return redoFn(state); },
        apply: redoFn,
    };
}
export function strong(schema) {
    return createMarkToggleTool("strong", "Bold", React.createElement(FormatBold, null), schema.marks.strong);
}
export function em(schema) {
    return createMarkToggleTool("em", "Italic", React.createElement(FormatItalic, null), schema.marks.em);
}
export function code(schema) {
    return createMarkToggleTool("code", "Code", React.createElement(Code, null), schema.marks.code);
}
export function editLink(type) {
    var _this = this;
    return function (state, dispatch, richTextEditorContext) { return __awaiter(_this, void 0, void 0, function () {
        var marks, attrs, linkValue, newAttrs, tr, selection, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    marks = getSelectionMarks(state).filter(function (mark) { return mark.mark.type === type; });
                    attrs = marks.length === 1 ? marks[0].mark.attrs : undefined;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, richTextEditorContext.dialogs.getHyperlink(attrs)];
                case 2:
                    linkValue = _a.sent();
                    if (linkValue.cancel) {
                        return [2 /*return*/];
                    }
                    newAttrs = {
                        href: linkValue.href,
                        title: linkValue.title === "" ? undefined : linkValue.title,
                    };
                    if (marks.length > 0) {
                        tr = state.tr, selection = state.selection;
                        if (newAttrs.href !== "") {
                            if (marks.length < 2 &&
                                !(selection.from < marks[0].from || selection.to > marks[0].to)) {
                                dispatch(tr.addMark(marks[0].from, marks[0].to, type.create(newAttrs)));
                            }
                            else {
                                dispatch(tr.addMark(selection.from, selection.to, type.create(newAttrs)));
                            }
                        }
                    }
                    else {
                        toggleMark(type, newAttrs)(state, dispatch);
                    }
                    return [2 /*return*/];
                case 3:
                    err_1 = _a.sent();
                    console.error(err_1);
                    return [3 /*break*/, 4];
                case 4:
                    if (isMarkActive(state, type)) {
                        dispatch(state.tr.removeMark(marks[0].from, marks[0].to, type));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
}
export function editCode(type) {
    var _this = this;
    return function (state, dispatch, richTextEditorContext) { return __awaiter(_this, void 0, void 0, function () {
        var params, val, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = getCurrentParams(state, type);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, richTextEditorContext.dialogs.getCode(params.params || "")];
                case 2:
                    val = _a.sent();
                    return [2 /*return*/, setBlockType(type, { params: val })(state, dispatch)];
                case 3:
                    err_2 = _a.sent();
                    console.error(err_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
}
export function link(schema) {
    return {
        name: "link",
        label: "Add or remove Link",
        displayIcon: React.createElement(Link, null),
        isActive: function (state) { return isMarkActive(state, schema.marks.link); },
        isEnabled: function (state) {
            return (!state.selection.empty ||
                getSelectionMarks(state).filter(function (mark) { return mark.mark.type === schema.marks.link; }).length > 0);
        },
        apply: editLink(schema.marks.link),
    };
}
export function insertImage(type) {
    var _this = this;
    return function (state, dispatch, richTextEditorContext) { return __awaiter(_this, void 0, void 0, function () {
        var imageValue, err_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, richTextEditorContext.dialogs.getImage()];
                case 1:
                    imageValue = _a.sent();
                    dispatch(state.tr.replaceSelectionWith(type.createAndFill({
                        src: imageValue.src,
                        title: imageValue.title === "" ? undefined : imageValue.title,
                        alt: imageValue.alt === "" ? undefined : imageValue.alt,
                    })));
                    return [3 /*break*/, 3];
                case 2:
                    err_3 = _a.sent();
                    console.error(err_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
}
export function image(schema) {
    return {
        name: "image",
        label: "Insert Image from URL",
        displayIcon: React.createElement(ImageIcon, null),
        apply: insertImage(schema.nodes.image),
    };
}
export function lift_tool(schema) {
    return {
        name: "lift",
        label: "Decrease Indentation",
        displayIcon: React.createElement(FormatIndentDecrease, null),
        isVisible: function (state) { return lift(state); },
        apply: lift,
    };
}
export function bullet_list(schema) {
    return {
        name: "bullet_list",
        label: "Bullet List",
        displayIcon: React.createElement(FormatListBulleted, null),
        isEnabled: function (state) { return wrapInList(schema.nodes.bullet_list, {})(state); },
        apply: wrapInList(schema.nodes.bullet_list, {}),
    };
}
export function ordered_list(schema) {
    return {
        name: "ordered_list",
        label: "Ordered List",
        displayIcon: React.createElement(FormatListNumbered, null),
        isEnabled: function (state) { return wrapInList(schema.nodes.ordered_list, {})(state); },
        apply: wrapInList(schema.nodes.ordered_list, {}),
    };
}
export function blockquote(schema) {
    return {
        name: "blockquote",
        label: "Quote",
        displayIcon: React.createElement(FormatQuote, null),
        isEnabled: function (state) { return wrapInList(schema.nodes.blockquote, {})(state); },
        apply: wrapInList(schema.nodes.blockquote, {}),
    };
}
function getCurrentAlignment(state) {
    if (state == null) {
        return {};
    }
    // Locate the block we're contained in.
    var parent = findParentNode(function (x) { return x.attrs.align; })(state.selection);
    if (parent != null) {
        return { align: parent.node.attrs.align };
    }
    else {
        return {};
    }
}
function getCurrentParams(state, type) {
    if (state == null || type.name !== "code_block") {
        return {};
    }
    // Locate the block we're contained in.
    var parent = findParentNode(function (x) { return x.attrs.params; })(state.selection);
    if (parent != null) {
        return { params: parent.node.attrs.params || "" };
    }
    else {
        return { params: "" };
    }
}
function blockTypeCommand(state, type, attrs) {
    return setBlockType(type, __assign(__assign({}, attrs), getCurrentAlignment(state)));
}
export function heading(schema, level) {
    return {
        name: "heading_" + level,
        label: "Heading " + level,
        displayLabel: React.createElement("h" + level, { style: { margin: 0 } }, "Heading " + level),
        apply: function (state, dispatch) {
            return blockTypeCommand(state, schema.nodes.heading, { level: level })(state, dispatch);
        },
        isEnabled: function (state) {
            return blockTypeCommand(state, schema.nodes.heading, { level: level })(state);
        },
        isActive: function (state, view) {
            return !blockTypeCommand(state, schema.nodes.heading, { level: level })(state, null);
        },
    };
}
export function paragraph(schema) {
    var command = setBlockType(schema.nodes.paragraph);
    return {
        name: "paragraph",
        label: "Normal text",
        displayLabel: (React.createElement("p", { style: { margin: 0, padding: 0, display: "inline" } }, "Normal text")),
        apply: function (state, dispatch) {
            return blockTypeCommand(state, schema.nodes.paragraph)(state, dispatch);
        },
        isEnabled: function (state) {
            return blockTypeCommand(state, schema.nodes.paragraph)(state);
        },
        isActive: function (state) {
            return !blockTypeCommand(state, schema.nodes.paragraph)(state, null);
        },
    };
}
/*
This is an example of creating a custom format using a class.
The method should be pushed to the tools array to appear in the format list in the RTE.
Currently only paragraph supports custom classes. This can be modified in the appropriate node type in packages\language-markdown\src\markdown\MarkdownSerializer.ts.
The example made for paragraphs can be found in AlignedParagraphToMarkdown.
*/
/* export function subheadline(schema: any): ProseMirrorTool {
  const command = setBlockType(schema.nodes.paragraph);
  return {
    name: "subheadline",
    label: "Subheadline",
    displayLabel: (
      <p style={{ margin: 0, padding: 0, display: "inline" }}>Subheadline</p>
    ),
    apply: (state: any, dispatch: any) =>
      blockTypeCommand(state, schema.nodes.paragraph, { class: 'subheadline' })(state, dispatch),
    isEnabled: (state: any) =>
      blockTypeCommand(state, schema.nodes.paragraph, { class: 'subheadline' })(state),
    isActive: (state: any) =>
      !blockTypeCommand(state, schema.nodes.paragraph, { class: 'subheadline' })(state, null),
  };
} */
export function code_block(schema) {
    return {
        name: "code_block",
        label: "Code Block",
        displayLabel: React.createElement("code", null, "Code Block"),
        displayIcon: React.createElement(Code, null),
        apply: editCode(schema.nodes.code_block),
        isActive: function (state, richTextEditorContext) {
            var params = getCurrentParams(state, schema.nodes.code_block);
            return !setBlockType(schema.nodes.code_block, __assign({}, params))(state, null);
        },
    };
}
export function horizontal_rule(schema) {
    return {
        name: "horizontal_rule",
        label: "Insert Horizontal Rule",
        displayIcon: React.createElement(CalendarViewDay, null),
        apply: function (state, dispatch) {
            dispatch(state.tr.replaceSelectionWith(schema.nodes.horizontal_rule.create()));
        },
        isEnabled: function (state) { return canInsert(state, schema.nodes.horizontal_rule); },
    };
}
export function clear_formatting() {
    return {
        name: "clear_formatting",
        label: "Clear Formatting",
        displayIcon: React.createElement(FormatClearIcon, null),
        apply: clearAllMarks(),
        isEnabled: function (state) {
            // are there any marks?
            return true;
        },
    };
}
export function ai_generate() {
    var _this = this;
    return {
        name: "ai",
        label: "AI Assistant",
        displayIcon: (React.createElement(Badge, { variant: "dot", badgeContent: "NEW", color: "error", overlap: "rectangle" },
            React.createElement(Assistant, { color: "primary" }))),
        apply: function (state, dispatch, richTextEditorContext) { return __awaiter(_this, void 0, void 0, function () {
            var prompt_1, err_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, richTextEditorContext.dialogs.getAIPrompt({
                                variant: "generate",
                            })];
                    case 1:
                        prompt_1 = _a.sent();
                        return [4 /*yield*/, richTextEditorContext.actions.insertAIContent(prompt_1)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_4 = _a.sent();
                        console.error(err_4);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); },
        isEnabled: function (state) {
            return true;
        },
    };
}
export function createStandardTools(schema, options) {
    var tools = [];
    if (isToolEnabled("undo", options)) {
        tools.push(undo());
    }
    if (isToolEnabled("redo", options)) {
        tools.push(redo());
    }
    if (isToolEnabled("strong", options) && schema.marks.strong) {
        tools.push(strong(schema));
    }
    if (isToolEnabled("em", options) && schema.marks.em) {
        tools.push(em(schema));
    }
    if (isToolEnabled("code", options) && schema.marks.code) {
        tools.push(code(schema));
    }
    if (isToolEnabled("link", options) && schema.marks.link) {
        tools.push(link(schema));
    }
    if (isToolEnabled("lift", options)) {
        tools.push(lift_tool(schema));
    }
    if (isToolEnabled("bullet_list", options) && schema.nodes.bullet_list) {
        tools.push(bullet_list(schema));
    }
    if (isToolEnabled("ordered_list", options) && schema.nodes.ordered_list) {
        tools.push(ordered_list(schema));
    }
    if (isToolEnabled("image", options) && schema.nodes.image) {
        tools.push(image(schema));
    }
    if (isToolEnabled("blockquote", options) && schema.nodes.blockquote) {
        tools.push(blockquote(schema));
    }
    if (isToolEnabled("heading", options) && schema.nodes.heading) {
        for (var i = 1; i < 7; i++) {
            tools.push(heading(schema, i));
        }
    }
    if (isToolEnabled("paragraph", options) && schema.nodes.paragraph) {
        tools.push(paragraph(schema));
    }
    if (isToolEnabled("code_block", options) && schema.nodes.code_block) {
        tools.push(code_block(schema));
    }
    if (isToolEnabled("horizontal_rule", options) &&
        schema.nodes.horizontal_rule) {
        tools.push(horizontal_rule(schema));
    }
    if (isToolEnabled("clear_formatting", options)) {
        tools.push(clear_formatting());
    }
    if (isToolEnabled("ai", options)) {
        tools.push(ai_generate());
    }
    return tools;
}
