var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { heading_align } from "../alignment/AlignmentPlugin";
import { anchor } from "../anchor";
import { inline_styles } from "../inline_styles";
import { createMarkdownParser } from "../markdown/MarkdownParser";
import { createMarkdownSerializer } from "../markdown/MarkdownSerializer";
import { soft_hyphen } from "../soft_hyphen";
// tslint:disable-next-line
var tableNodes = require("prosemirror-tables").tableNodes;
// tslint:disable-next-line
var Schema = require("prosemirror-model").Schema;
/*
This functions extends the defaults of prosemirror-markdown.
To change attributes of nodes and how they are converted to/from Markdown add them to this createSchema function.
For a reference of existing schemas see this file: https://github.com/ProseMirror/prosemirror-markdown/blob/1.3.1/src/schema.js
*/
export function createSchema(options, isInlineStylesEnabled) {
    if (isInlineStylesEnabled === void 0) { isInlineStylesEnabled = false; }
    var schema = require("prosemirror-markdown").schema;
    // TODO: don't register nodes and marks that are disabled in the options
    var marks = schema.spec.marks;
    if (isInlineStylesEnabled) {
        marks = marks.addToEnd("inline_styles", inline_styles);
    }
    marks = marks.update("link", {
        attrs: {
            href: {},
            title: { default: null },
        },
        inclusive: false,
        toDOM: function (node) {
            return ["a", node.attrs];
        },
        parseDOM: [
            {
                tag: "a[href]",
                getAttrs: function (dom) {
                    return {
                        href: dom.getAttribute("href"),
                        title: dom.getAttribute("title"),
                    };
                },
            },
        ],
    });
    var nodes = schema.spec.nodes
        .append(tableNodes({
        tableGroup: "block",
        cellContent: "block",
        cellAttributes: {
            background: {
                default: null,
                // tslint:disable-next-line
                getFromDOM: function (dom) {
                    return dom.style.backgroundColor || null;
                },
                // tslint:disable-next-line
                setDOMAttr: function (value, attrs) {
                    if (value) {
                        attrs.style =
                            (attrs.style || "") + ("background-color: " + value + ";");
                    }
                },
            },
        },
    }))
        .addToEnd("soft_hyphen", soft_hyphen)
        .addToEnd("anchor", anchor)
        //.update("paragraph", paragraph_align)
        .update("paragraph", {
        content: "inline*",
        attrs: {
            class: { default: "" },
            align: { default: "left" },
        },
        group: "block",
        parseDOM: [
            {
                tag: "p",
                getAttrs: function (dom) {
                    return __assign({ align: dom.style.textAlign }, (dom.attr ? { class: dom.attrs.class } : {}));
                },
            },
        ],
        toDOM: function (node) {
            return [
                "p",
                {
                    style: "text-align: " + (node.attrs.align || "left"),
                    class: "" + node.attrs.class,
                },
                0,
            ];
        },
    })
        .update("heading", heading_align);
    return new Schema({
        nodes: nodes,
        marks: marks,
    });
}
var serializer;
var parser;
export function getDefaultSerializerParser(schema, options) {
    if (serializer == null) {
        serializer = createMarkdownSerializer(options);
        parser = createMarkdownParser(schema, options);
    }
    return [serializer, parser];
}
