var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { createStandardTools, isToolEnabled, } from "@dc-extension-rich-text/common";
import { AlignCenterTool, AlignJustifyTool, AlignLeftTool, AlignRightTool, } from "./alignment/AlignmentTools";
import { AnchorTool } from "./anchor";
import { createInlineStylesTools } from "./inline_styles";
import { createMarkdownParser } from "./markdown/MarkdownParser";
import { createMarkdownSerializer } from "./markdown/MarkdownSerializer";
import { createSchema } from "./schema/createSchema";
import { SoftHyphenTool } from "./soft_hyphen";
import { AddColumnTool, AddRowTool, CreateTableTool, DeleteColumnTool, DeleteRowTool, DeleteTableTool, } from "./tables/TableTools";
export function createMarkdownTools(schema, options) {
    var tools = [];
    if (isToolEnabled("inline_styles", options)) {
        tools = tools.concat(tools, createInlineStylesTools(schema, options));
    }
    if (isToolEnabled("anchor", options) && schema.nodes.anchor) {
        tools.push(AnchorTool(schema));
    }
    if (isToolEnabled("soft_hyphen", options) && schema.nodes.soft_hyphen) {
        tools.push(SoftHyphenTool(schema));
    }
    // Alignment
    if (isToolEnabled("align_left", options)) {
        tools.push(AlignLeftTool(schema));
    }
    if (isToolEnabled("align_center", options)) {
        tools.push(AlignCenterTool(schema));
    }
    if (isToolEnabled("align_right", options)) {
        tools.push(AlignRightTool(schema));
    }
    if (isToolEnabled("align_justify", options)) {
        tools.push(AlignJustifyTool(schema));
    }
    if (schema.nodes.table) {
        if (isToolEnabled("table_create", options)) {
            tools.push(CreateTableTool(schema));
        }
        if (isToolEnabled("table_delete", options)) {
            tools.push(DeleteTableTool(schema));
        }
        if (isToolEnabled("table_row_add", options)) {
            tools.push(AddRowTool(schema));
        }
        if (isToolEnabled("table_col_add", options)) {
            tools.push(AddColumnTool(schema));
        }
        if (isToolEnabled("table_row_delete", options)) {
            tools.push(DeleteRowTool(schema));
        }
        if (isToolEnabled("table_col_delete", options)) {
            tools.push(DeleteColumnTool(schema));
        }
    }
    return tools;
}
var MarkdownLanguage = /** @class */ (function () {
    function MarkdownLanguage(options) {
        if (options === void 0) { options = {}; }
        this.name = "markdown";
        this.label = "Markdown";
        var schema = this.createSchema(options);
        var tools = __spreadArrays(createStandardTools(schema, options), createMarkdownTools(schema, options));
        var serializer = createMarkdownSerializer(options, this.getNodeSerializers());
        var parser = createMarkdownParser(schema, options);
        this.schema = schema;
        this.tools = tools;
        this.serializer = serializer;
        this.parser = parser;
    }
    MarkdownLanguage.prototype.serialize = function (doc) {
        return this.serializeMarkdown(doc);
    };
    MarkdownLanguage.prototype.parse = function (data) {
        return this.parseMarkdown(data);
    };
    MarkdownLanguage.prototype.serializeMarkdown = function (doc) {
        return this.serializer.serialize(doc);
    };
    MarkdownLanguage.prototype.canSerializeNodeToMarkdown = function (schema, node) {
        if (!node.type) {
            return false;
        }
        else {
            return this.serializer.nodes[node.type] != null;
        }
    };
    MarkdownLanguage.prototype.parseMarkdown = function (data) {
        if (!data) {
            data = "";
        }
        return this.parser.parse(data);
    };
    MarkdownLanguage.prototype.getNodeSerializers = function () {
        return {};
    };
    MarkdownLanguage.prototype.createSchema = function (options) {
        if (options === void 0) { options = {}; }
        var isInlineStylesEnabled = isToolEnabled("inline_styles", options);
        return createSchema(options, isInlineStylesEnabled);
    };
    return MarkdownLanguage;
}());
export default MarkdownLanguage;
