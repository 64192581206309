var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AlignedHeaderToMarkdown, AlignedParagraphToMarkdown, } from "../alignment/AlignmentPlugin";
import { AnchorToMarkdown } from "../anchor";
import { InlineStylesToMarkdown } from "../inline_styles";
import { SoftHyphenToMarkdown } from "../soft_hyphen";
import { TableToMarkdown } from "../tables/TableToMarkdown";
// tslint:disable-next-line
var markdown = require("prosemirror-markdown");
function escape(text) {
    return text
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
}
var TextToMarkdown = {
    text: function (state, node) {
        state.text(escape(node.text));
    },
};
export function createMarkdownSerializer(options, serializers) {
    if (serializers === void 0) { serializers = {}; }
    return new markdown.MarkdownSerializer(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, markdown.defaultMarkdownSerializer.nodes), SoftHyphenToMarkdown), AnchorToMarkdown), TableToMarkdown), AlignedParagraphToMarkdown(options)), AlignedHeaderToMarkdown(options)), TextToMarkdown), serializers), __assign(__assign({}, markdown.defaultMarkdownSerializer.marks), InlineStylesToMarkdown));
}
